/* eslint-disable @typescript-eslint/no-explicit-any */
import { HierarchyNode, stratify, tree } from "d3-hierarchy";
import { Node } from "reactflow";

const createMutipleTrees = (root: HierarchyNode<unknown>): HierarchyNode<unknown>[] => {
    const descendants = root.descendants();

    const roots = descendants.reduce(
        (rootArr: any[], descendant: any) => {
            if (descendant?.data?.data?.isFinalAction) {
                return rootArr.concat(descendant);
            }

            return rootArr;
        },
        [root]
    );

    return roots;
};

const createGraphLayout = (nodes: Node[]) => {
    if (nodes.length > 0) {
        const root: HierarchyNode<unknown> = stratify()
            .id((d: any) => d.id)
            .parentId((d: any) => d.data.parentId)(nodes);
        const roots: HierarchyNode<unknown>[] = createMutipleTrees(root);

        // Compute the layout.
        const dx = 350;
        const dy = 200;

        let numberOfFinalAction = 0;
        let currentRoot = roots?.[0];
        tree().nodeSize([dx, dy])(currentRoot);
        let additionalHeight = 0;

        return nodes.map((node) => {
            const treeNode: any = currentRoot.descendants().find((descendant: any) => descendant.id == node.id);
            const isFinalAction = treeNode?.data?.data?.isFinalAction ?? false;

            if (isFinalAction) {
                numberOfFinalAction = numberOfFinalAction + 1;
                currentRoot = roots?.[numberOfFinalAction];
                tree().nodeSize([dx, 500])(currentRoot);
            }

            const branchNode: any = isFinalAction
                ? roots?.[0]?.descendants()?.find((descendant: any) => {
                      return descendant.id === (currentRoot?.parent?.data as any)?.data?.branchId;
                  })
                : null;
            let y = Number(treeNode?.y ?? 0);
            y = y + additionalHeight;

            if (["email", "sms"].includes(node.data.nodeType as string)) {
                additionalHeight += 40;
            }

            const positionX = !isFinalAction ? Number(treeNode?.x ?? 0) : branchNode?.x ?? 0;
            const positionY = y;

            return {
                ...node,
                position: {
                    x: positionX,
                    y: positionY,
                },
            };
        });
    }

    return nodes;
};

export default createGraphLayout;
