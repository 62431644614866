/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from "react";

import { UploadOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import type { UploadProps, UploadFile } from "antd";
import { Button, Form, Input, Upload, Row, Col } from "antd";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import { shallow } from "zustand/shallow";
import { isEmpty } from "lodash-es";

import { IFormsProps, IPreviewEmailTemplate } from "../../../types";
import TinyMCEEditor from "../../TinyMCEEditor";
import FormFooter from "../FormFooter";
import Spinner from "components/Spinner";
import { useGetTemplate } from "hooks/features/templates/queries";
import {
    useCreateTemplateMutation,
    useUpdateTemplateMutation,
    useUploadAttachmentFile,
} from "hooks/features/templates/mutations";
import { useRemoveFile } from "hooks/features/files/mutations";
import useStore, { RFState } from "stores";
import { UploadItemList } from "./SendEmailForm.styled";
import PreviewEmailTemplate from "./PreviewEmailTemplate/PreviewEmailTemplate";
import TemplateTagDropdown from "components/TemplateTagDropdown";

interface IFormInputs {
    fromName: string;
    to: string;
    from: string;
    replyTo: string;
    subject: string;
    templateId: number;
    message: string;
    attachmentFiles: UploadChangeParam;
}

const selector = (state: RFState) => ({
    conditionFields: state.conditionFields,
});

/**
 * TODO: Implement template picker later on.
 */
export default function SendEmailForm({ initialData, canDelete = true, onCancel, onSubmit, onDelete }: IFormsProps) {
    const [emailTemplate, setEmailTemplate] = useState<IPreviewEmailTemplate>();
    const [visibleModal, setVisibleModal] = useState(false);
    const [form] = Form.useForm();
    const { conditionFields } = useStore(selector, shallow);
    const createTemplateMutation = useCreateTemplateMutation();
    const updateTemplateMutation = useUpdateTemplateMutation(Number(initialData?.templateId));
    const uploadAttachmentFile = useUploadAttachmentFile();
    const removeFile = useRemoveFile();
    const { data: templateDetailData, isLoading: isTemplateDetailDataLoading } = useGetTemplate(
        Number(initialData?.templateId),
        !!initialData?.templateId
    );
    const [fileList, setFileList] = React.useState<UploadFile[]>([]);
    const initialValues = {
        fromName: String(initialData?.fromName ?? ""),
        to: String(initialData?.to ?? ""),
        subject: String(initialData?.subject ?? ""),
        templateId: String(initialData?.templateId ?? ""),
        message: String(initialData?.message ?? ""),
    };

    const getAttachmentFileUrl = (url: string) => {
        return `${process.env.REACT_APP_BASE_URL || "https://app.pilotpractice.com"}/${url}`;
    };

    const getAttachmentFiles = React.useCallback(() => {
        return (
            templateDetailData?.attachmentFiles?.map((attachmentFile) => {
                return {
                    uid: String(attachmentFile.id),
                    name: attachmentFile.originalName,
                    status: "done",
                    url: getAttachmentFileUrl(attachmentFile.url),
                } as UploadFile;
            }) ?? []
        );
    }, [templateDetailData]);

    const uploadProps: UploadProps = {
        ...(!!initialData?.templateId && {
            customRequest: async (options: any) => {
                const { onSuccess, onError } = options;
                try {
                    const data = new FormData();
                    data.append("attachmentFile", options.file as RcFile);

                    const attachmentFile = await uploadAttachmentFile.mutateAsync({
                        templateId: Number(initialData?.templateId),
                        formData: data,
                    });
                    setFileList([
                        ...fileList,
                        {
                            uid: String(attachmentFile.id),
                            name: attachmentFile.originalName,
                            status: "done",
                            url: getAttachmentFileUrl(attachmentFile.url),
                        },
                    ]);
                    onSuccess("Ok");
                } catch (e) {
                    onError("Error");
                }
            },
        }),
        beforeUpload(file) {
            if (!initialData?.templateId) {
                setFileList([...fileList, file]);

                return false;
            }
        },
        onRemove: async (file) => {
            try {
                if (initialData?.templateId) {
                    await removeFile.mutateAsync(Number(file.uid));
                }
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
            } catch (e) {
                console.log(e);
            }
        },
        fileList,
        itemRender: (originNode) => {
            return <UploadItemList>{originNode}</UploadItemList>;
        },
        showUploadList: {
            removeIcon: <DeleteOutlined />,
        },
    };

    const sumbit = async (data: IFormInputs) => {
        let newTemplateId = initialData?.templateId ?? null;

        // Create a new template if no template is selected.
        if (!initialData?.templateId) {
            const formData = new FormData();
            formData.append("name", initialData.label as string);
            formData.append("type", "email");
            formData.append("to", String(data.to));
            formData.append("from", String(data.from));
            formData.append("from_name", String(data.fromName));
            formData.append("replyTo", String(data.replyTo));
            formData.append("subject", data.subject);
            formData.append("content", data.message);
            fileList?.forEach((file: UploadFile) => {
                formData.append("attachment_files[]", file as RcFile);
            });

            const response = await createTemplateMutation.mutateAsync(formData);
            newTemplateId = response.id;
        } else {
            await updateTemplateMutation.mutateAsync({
                name: initialData.label as string,
                to: data.to,
                from: data.from,
                fromName: data.fromName,
                replyTo: data.replyTo,
                subject: data.subject,
                content: data.message,
            });
        }

        onSubmit({
            ...data,
            fileList,
            ...(newTemplateId ? { templateId: newTemplateId } : { templateId: Number(initialData.templateId) }),
        });
    };

    const updateFieldValueWithTag = (fieldName: string, value: string) => {
        const currentValue = form.getFieldValue(fieldName);

        form.setFieldValue(fieldName, `${currentValue}{{${value}}}`);
    };

    const onShowPreviewTemplate = () => {
        setVisibleModal(true);
        const message = form.getFieldValue("message");
        const subject = String(form.getFieldValue("subject"));
        setEmailTemplate({
            templateId: initialData?.templateId,
            message,
            subject,
            fileList,
        });
    };

    React.useEffect(() => {
        if (!!initialData?.templateId && !!templateDetailData) {
            setFileList(() => [...getAttachmentFiles()]);
        }
    }, [initialData, templateDetailData]);

    React.useEffect(() => {
        if (initialData && !isEmpty(initialData)) {
            form.setFieldValue("fromName", String(initialData?.fromName ?? ""));
            form.setFieldValue("to", String(initialData?.to ?? ""));
            form.setFieldValue("from", String(initialData?.from ?? ""));
            form.setFieldValue("replyTo", String(initialData?.replyTo ?? ""));
            form.setFieldValue("subject", String(initialData?.subject ?? ""));
            form.setFieldValue("templateId", String(initialData?.templateId ?? ""));
            form.setFieldValue("message", String(initialData?.message ?? ""));
        }
    }, [initialData, form]);

    if (!!initialData?.tempate && isTemplateDetailDataLoading) return <Spinner />;

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={sumbit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="From Name" name="fromName">
                            <Input
                                placeholder="From Name"
                                suffix={<TemplateTagDropdown fieldName="fromName" onSelect={updateFieldValueWithTag} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="To"
                            name="to"
                            rules={[
                                {
                                    required: true,
                                    message: "To is required field",
                                },
                                // {
                                //     message: "To can only be an email or {{email}}",
                                //     validator: (_, value: string) => {
                                //         const emailPattern = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                                //         const emailTagPattern = /^{{email}}$/g;
                                //         if (isEmpty(value) || emailPattern.test(value) || emailTagPattern.test(value)) {
                                //             return Promise.resolve();
                                //         }

                                //         return Promise.reject("To can only be an email or {{email}}");
                                //     },
                                // },
                            ]}
                        >
                            <Input
                                placeholder="To"
                                suffix={<TemplateTagDropdown fieldName="to" onSelect={updateFieldValueWithTag} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="From Email"
                            name="from"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "From is required field",
                            //     },
                            // ]}
                        >
                            <Input
                                placeholder="From Email"
                                suffix={<TemplateTagDropdown fieldName="from" onSelect={updateFieldValueWithTag} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Reply To"
                            name="replyTo"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: "Reply To is required field",
                            //     },
                            // ]}
                        >
                            <Input
                                placeholder="Reply To"
                                suffix={<TemplateTagDropdown fieldName="replyTo" onSelect={updateFieldValueWithTag} />}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item label="Subject" name="subject">
                    <Input
                        placeholder="Subject"
                        suffix={<TemplateTagDropdown fieldName="subject" onSelect={updateFieldValueWithTag} />}
                    />
                </Form.Item>
                <Form.Item
                    name="message"
                    label="Message"
                    rules={[{ required: true, message: "Message is required field" }]}
                >
                    <TinyMCEEditor
                        plugins={"code"}
                        toolbar={
                            "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | code | templatetags"
                        }
                        conditionFields={conditionFields}
                        uiMode="split"
                    />
                </Form.Item>
                <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>Add attachment</Button>
                </Upload>
                <FormFooter
                    isLoading={createTemplateMutation.isLoading || updateTemplateMutation.isLoading}
                    onCancel={onCancel}
                    canDelete={canDelete}
                    onDelete={onDelete}
                    extraActions={
                        <>
                            <Button type="default" size="large" onClick={onShowPreviewTemplate} icon={<EyeOutlined />}>
                                Preview
                            </Button>
                        </>
                    }
                />
            </Form>
            {visibleModal && !!emailTemplate && (
                <PreviewEmailTemplate
                    emailTemplate={emailTemplate}
                    visibleModal={visibleModal}
                    onCancel={() => setVisibleModal(false)}
                />
            )}
        </div>
    );
}
