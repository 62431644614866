import type { MenuProps } from "antd";
import { Dropdown, Button, Divider, Typography } from "antd";
import { shallow } from "zustand/shallow";

import More from "../../assets/more.svg";
import Action from "../../assets/action.svg";
import useStore, { RFState } from "../../stores";
import { BLOCKS } from "contestants/blockIcons";

interface IKloudMDNodeHeaderProps {
    id: string;
    data?: any;
}

const selector = (state: RFState) => ({
    deleteNode: state.deleteNode,
    deleteBranchNode: state.deleteBranchNode,
    toggleNodeActionDropdown: state.toggleNodeActionDropdown,
    metrics: state.currentMetrics,
});

const KloudMDNodeHeader = ({ id, data }: IKloudMDNodeHeaderProps) => {
    const {
        deleteNode,
        deleteBranchNode,
        toggleNodeActionDropdown,
        metrics: metricsStore,
    } = useStore(selector, shallow);

    const items: MenuProps["items"] = [
        {
            label: (
                <Button
                    type="link"
                    onClick={(e) => {
                        e.stopPropagation();
                        if (data?.branchId) {
                            deleteBranchNode(id);
                        } else {
                            deleteNode(id);
                        }
                    }}
                >
                    Delete
                </Button>
            ),
            key: "delete",
        },
    ].filter((item) => !(data?.default && item.key === "delete"));

    const icon = BLOCKS.find((block) => block?.type === data.nodeType)?.icon;

    const renderMetrics = () => {
        if (!["sms", "email"].includes(data.nodeType as string)) return null;
        const foundMetrics = metricsStore?.find((metric) => metric.actionId === data.uuid);
        if (!foundMetrics) return null;
        const metrics = [
            { label: `${data.nodeType === "sms" ? "SMS" : "Emails"} Sent:`, value: foundMetrics?.sentCount || 0 },
            ...(data.nodeType === "email"
                ? [
                      { label: "Emails Opened:", value: foundMetrics?.openedCount || 0 },
                      {
                          label: "Open Rate:",
                          value: `${
                              isNaN(foundMetrics?.openedCount / foundMetrics?.sentCount)
                                  ? 0
                                  : ((foundMetrics?.openedCount / foundMetrics?.sentCount) * 100).toFixed(2)
                          }%`,
                      },
                  ]
                : []),
        ];

        return (
            <>
                <Divider style={{ margin: "4px 0" }} />
                <div className="metrics-card">
                    {metrics.map((metric, index) => (
                        <div key={index} className="metric">
                            <Typography.Text type="secondary" className="metric-label">
                                {metric.label}
                            </Typography.Text>
                            <span className="metric-value">{metric.value}</span>
                        </div>
                    ))}
                </div>
            </>
        );
    };

    return (
        <div className="block-header">
            <div className="block-header-top">
                <div className="details">
                    <div className="icon">{icon ? icon : <img src={Action} alt="action" />}</div>
                    <div className="label">{data?.label}</div>
                </div>
                {items && items.length > 0 && (
                    <div className="actions">
                        <Dropdown
                            menu={{ items }}
                            trigger={["click"]}
                            open={data?.visibleActions ?? false}
                            onOpenChange={(open) => {
                                toggleNodeActionDropdown(id, open);
                            }}
                        >
                            <Button type="text" onClick={(e) => e.stopPropagation()}>
                                <img src={More} alt="More" aria-hidden />
                            </Button>
                        </Dropdown>
                    </div>
                )}
            </div>
            {renderMetrics()}
        </div>
    );
};

export default KloudMDNodeHeader;
