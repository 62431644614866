import { plainToClass } from "class-transformer";
import "reflect-metadata";
import type { v4 } from "uuid";
import { v4 as uuidv4 } from "uuid";
import { Node, Edge, MarkerType, getOutgoers } from "reactflow";
import { camelizeKeys, decamelizeKeys } from "humps";
import { omit, isEmpty } from "lodash-es";

import {
    IBranches,
    ICondition,
    IConditionSets,
    IWorkflowActionResponse,
    IWorkflowResponse,
    IWorkflowSettings,
} from "../types";

export interface IActionParserParams {
    parent: Action;
    actions?: Action[];
    finalAction?: Action;
    nodes?: Node[];
    edges?: Edge[];
    level: number;
    branchId?: number | string | typeof v4 | null;
}

export interface IWorkflowParserResponse {
    nodes?: Node[];
    edges?: Edge[];
}

class Condition {
    uuid?: number | string | typeof v4;

    conditionSets: IConditionSets;
}

abstract class Action {
    id?: number | string | typeof v4;

    name: string;

    type: string;

    condition?: Condition;

    actions: Action[];

    action?: Action;

    default?: boolean;

    public add(action: Action): void {
        this.actions.push(action);
    }

    public remove(action: Action): void {
        if (action?.id) {
            const actionIndex = this.actions.findIndex((child) => child.id === action.id);
            this.actions.splice(actionIndex, 1);
        }
    }

    public hasChildNodes(): boolean {
        return this?.actions && this.actions.length > 0;
    }
}

class Wait extends Action {
    duration: number;

    duration_unit: string;
}

class SendEmail extends Action {
    template_id: string;
}

class SendSMS extends Action {
    template_id: string;
}

class Branch extends Action {}

class If extends Action {}

class Jump extends Action {
    jump_to: string;
}

class ContactUpdate extends Action {
    field: string;

    value: string;
}

class AssignLead extends Action {
    type: string;

    staff_ids: number[];
}

class UnassignLead extends Action {}

class CreateTicket extends Action {
    subject: string;

    type: string;

    staff_ids: number[];

    description: string;
}

class CreatePayment extends Action {
    amount: number;

    currency: string;

    type_payment: string;
}
class CreateReminder extends Action {
    name_task: string;

    description: string;

    staff_id: number;

    time_number: string;

    unit: string;
}

class ConditionAction extends Action {}

class PlaceholderAction extends Action {}

class Trigger {
    id?: number | string | typeof v4;

    name: string;

    type: string;

    event: string;

    condition?: Condition;

    action: Action;

    uuid?: string;
}

export class Workflow {
    id?: number | string | typeof v4;

    name: string;

    trigger: Trigger;

    projectId?: number;

    uuid?: string;

    communicateOnlyDuringBusinessHours: boolean;

    excludedWorkflowIds: number[];

    constructor(data: IWorkflowResponse) {
        this.loadFromJson(data);
    }

    deserializeActions(actionData: IWorkflowActionResponse): Action {
        switch (actionData.type) {
            case "wait":
                return plainToClass(Wait, actionData);
            case "email":
                return plainToClass(SendEmail, actionData);
            case "sms":
                return plainToClass(SendSMS, actionData);
            case "branch":
                return plainToClass(Branch, actionData);
            case "if":
                return plainToClass(If, actionData);
            case "jump":
                return plainToClass(Jump, actionData);
            case "condition":
                return plainToClass(ConditionAction, actionData);
            case "contact_update":
                return plainToClass(ContactUpdate, actionData);
            case "assign_lead":
                return plainToClass(AssignLead, actionData);
            case "unassign_lead":
                return plainToClass(UnassignLead, actionData);
            case "create_reminder":
                return plainToClass(CreateReminder, actionData);
            case "create_task":
                return plainToClass(CreateTicket, actionData);
            case "create_payment":
                return plainToClass(CreatePayment, actionData);
            case "placeholder":
                return plainToClass(PlaceholderAction, actionData);
            default:
                throw new Error(`Unknown action type: ${actionData.type}`);
        }
    }

    deserializeActionTree(actionData: IWorkflowActionResponse): Action {
        const newActionData: IWorkflowActionResponse = {
            ...actionData,
            id: actionData?.id ?? uuidv4(),
        };
        const action = this.deserializeActions(newActionData);
        if (action?.condition) {
            action.condition = plainToClass(Condition, action.condition);
        }
        if (action?.type === "branch") {
            if (action?.action) {
                action.action = this.deserializeActionTree({
                    ...action.action,
                    id: action?.action?.id ?? uuidv4(),
                });
            } else {
                action.action = this.deserializeActionTree({
                    id: action?.action?.id ?? uuidv4(),
                    name: "placeholder",
                    type: "placeholder",
                });
            }

            // Convert a condition property to a condition action.
            if (!isEmpty(action.actions)) {
                action.actions =
                    action?.actions.map((data: Action) => {
                        const newConditionAction = plainToClass(ConditionAction, {
                            id: data?.condition?.uuid ?? uuidv4(),
                            name: "condition",
                            type: "condition",
                            ...(!data?.default
                                ? {
                                      condition: {
                                          conditionSets: data?.condition?.conditionSets ?? [],
                                      },
                                  }
                                : { default: true }),
                            actions: [data],
                        });

                        return newConditionAction;
                    }) ?? [];
            }
        }

        if (action.actions) {
            action.actions = action.actions.map((data: Action) => this.deserializeActionTree(data));
        }

        return action;
    }

    loadFromJson(data: IWorkflowResponse) {
        this.id = data?.id;
        this.name = data?.name;
        if (data?.trigger) {
            this.trigger = plainToClass(Trigger, {
                ...data.trigger,
                ...(!data.trigger?.id && { id: uuidv4() }),
            });

            if (data.trigger?.action) {
                this.trigger.action = this.deserializeActionTree(data.trigger?.action);
            }
        }
    }

    getBranchesFromChildren(action: Action) {
        if (action.type === "branch") {
            return action.actions.reduce((branches: IBranches, currentAction) => {
                const conditionSets = currentAction?.condition?.conditionSets;
                if (conditionSets !== undefined && conditionSets.length > 0) {
                    return branches.concat({
                        id: String(currentAction?.id),
                        name: currentAction?.name,
                        isDefault: currentAction?.default ?? false,
                        conditionSets,
                    });
                }

                return branches;
            }, []);
        }

        return [];
    }

    getBranchChildNodes(action: Action, childNodes: Action[]) {
        if (!action.hasChildNodes()) {
            return;
        }

        action?.actions.forEach((child) => {
            childNodes.push(child);
            this.getBranchChildNodes(child, childNodes);
        });
    }

    getDepthestNodeInBranch(actions: Action[]): Action {
        let depthestNode = actions?.[0];
        let maxDepth = 0;

        actions.forEach((child) => {
            const childNodes: Action[] = [];
            this.getBranchChildNodes(child, childNodes);
            if (maxDepth < childNodes.length) {
                maxDepth = childNodes.length;
                depthestNode = childNodes?.slice(-1)?.[0];
            }
        });

        return depthestNode;
    }

    getIfActionBranches(action: Action) {
        return [
            camelizeKeys({
                ...action?.condition,
                isDefault: false,
                id: String(action?.id),
                name: action?.name,
            }),
        ];
    }

    /**
     * A default condition node should set the name as default.
     * @param param0
     */
    convertActionsToReactflow({
        parent,
        actions,
        finalAction,
        nodes,
        edges,
        level,
        branchId = null,
    }: IActionParserParams): void {
        if (actions && actions.length > 0) {
            actions?.forEach((action, index) => {
                const parentId = parent.id;
                nodes?.push({
                    id: String(action.id),
                    type: action.hasChildNodes() || finalAction || branchId ? "kloudMDDefault" : "kloudMDOutput",
                    position: { x: index * 200, y: (Number(level) + index + 1) * 150 },
                    data: {
                        label: action.type === "condition" && action?.default ? "default" : action.name,
                        nodeType: action.type,
                        workflowType: "action",
                        parentId,
                        ...camelizeKeys({ ...omit(action, ["id", "name", "type", "actions"]) }),
                        ...(action.type === "branch" && {
                            branches: this.getBranchesFromChildren(action),
                            finalActionId: action?.action?.id,
                        }),
                        ...(parent &&
                            parent.type === "branch" && {
                                branchId: parentId,
                            }),
                        ...(branchId && {
                            branchId,
                        }),
                        ...(action.type === "if" && {
                            branches: this.getIfActionBranches(action),
                        }),
                    },
                });
                edges?.push({
                    id: `${parentId}-${action.id}`,
                    source: String(parentId),
                    target: String(action.id),
                    type: "smoothstep",
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 25,
                        height: 25,
                    },
                });
                // Connect branch's child node with final action.
                if (!action.hasChildNodes() && branchId) {
                    const rootBranch = nodes?.find((node) => node.id === branchId);
                    if (rootBranch && rootBranch?.data?.finalActionId) {
                        edges?.push({
                            id: `${action.id}-${rootBranch?.data?.finalActionId}`,
                            source: String(action.id),
                            target: String(rootBranch?.data?.finalActionId),
                            type: "smoothstep",
                            markerEnd: {
                                type: MarkerType.ArrowClosed,
                                width: 25,
                                height: 25,
                            },
                            data: {
                                showStartButton: true,
                            },
                        });
                    }
                }
                if (action?.actions && action?.actions?.length > 0) {
                    if (action?.id) {
                        this.convertActionsToReactflow({
                            parent: action,
                            actions: action?.actions,
                            finalAction: action?.action,
                            nodes,
                            edges,
                            level: Number(level) + 1,
                            branchId: parent.type === "branch" && !branchId ? parentId : branchId,
                        });
                    }
                }
            });
        }

        if (parent.type === "branch") {
            // Add the final action to the tree.
            const depthestNode = this.getDepthestNodeInBranch(actions as Action[]);
            if (finalAction && !isEmpty(finalAction)) {
                let finalActionType = "kloudMDDefault";

                if (!finalAction?.actions) {
                    finalActionType = "kloudMDOutput";
                }

                if (finalAction?.type === "placeholder") {
                    finalActionType = "placeholder";
                }

                nodes?.push({
                    id: String(finalAction.id),
                    type: finalActionType,
                    position: { x: 0 * 200, y: (Number(level) + 0 + 1) * 150 },
                    data: {
                        label: finalAction.name,
                        nodeType: finalAction.type,
                        workflowType: "action",
                        parentId: depthestNode?.id, // Temporary parent node that used for d3 hierachy.
                        isFinalAction: true,
                        ...camelizeKeys({ ...omit(finalAction, ["id", "name", "type", "actions"]) }),
                        ...(finalAction.type === "branch" && {
                            branches: this.getBranchesFromChildren(finalAction),
                        }),
                    },
                });
                if (finalAction?.actions && finalAction?.actions?.length > 0) {
                    if (finalAction?.id) {
                        this.convertActionsToReactflow({
                            parent: finalAction,
                            actions: finalAction?.actions,
                            finalAction: finalAction?.action,
                            nodes,
                            edges,
                            level: Number(level) + 2,
                        });
                    }
                }
            }
        }
    }

    buildReactflowTree(): IWorkflowParserResponse {
        const nodes: Node[] = [];
        const edges: Edge[] = [];
        if (this?.trigger) {
            const trigger = {
                ...this?.trigger,
                id: this?.trigger?.id ?? uuidv4(),
            };
            nodes.push({
                id: String(trigger.id),
                type: "kloudMDInput",
                position: { x: 0, y: 0 },
                data: {
                    label: trigger.name,
                    nodeType: trigger?.type,
                    workflowType: "trigger",
                    ...camelizeKeys({ ...omit(trigger, ["id", "name", "type", "action"]) }),
                },
            });

            if (trigger?.action) {
                const action = this.trigger?.action;
                if (action?.id) {
                    nodes.push({
                        id: String(action.id),
                        position: { x: 0, y: 150 },
                        type: "kloudMDDefault",
                        data: {
                            label: action.name,
                            nodeType: action.type,
                            workflowType: "action",
                            parentId: trigger?.id,
                            ...camelizeKeys({ ...omit(action, ["id", "name", "type", "actions"]) }),
                            ...(action.type === "branch" && {
                                branches: this.getBranchesFromChildren(action),
                                finalActionId: action?.action?.id,
                            }),
                            ...(action.type === "if" && {
                                branches: this.getIfActionBranches(action),
                            }),
                        },
                    });
                    edges?.push({
                        id: `${trigger.id}-${action.id}`,
                        source: String(trigger.id),
                        target: String(action.id),
                        type: "smoothstep",
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                            width: 25,
                            height: 25,
                        },
                    });
                    if (action?.actions && action?.actions.length > 0) {
                        const level = 0;
                        this.convertActionsToReactflow({
                            parent: action,
                            actions: action?.actions,
                            finalAction: action?.action,
                            nodes,
                            edges,
                            level: level + 1,
                        });
                    }
                }
            }
        }

        return { nodes, edges };
    }

    getIfConditionFromData(nodeId: string | typeof v4, nodeData: any) {
        const condition = nodeData?.branches?.[0];

        return {
            condition: {
                uuid: nodeId,
                conditionSets:
                    condition?.conditionSets?.map((conditionSet: ICondition[]) => {
                        // Add uuid property to a condition.
                        return conditionSet.map((cond: ICondition) => {
                            return {
                                ...cond,
                                uuid: cond.id,
                            };
                        });
                    }) ?? [],
            },
        };
    }

    setActionFromNode(nodeId: string | typeof v4, nodeData: any) {
        return omit(
            {
                ...nodeData,
                name: nodeData?.label,
                type: nodeData?.nodeType,
                id: nodeId,
                uuid: nodeId,
                ...(nodeData?.nodeType === "if" && this.getIfConditionFromData(nodeId, nodeData)),
            },
            [
                "label",
                "nodeType",
                "workflowType",
                "parentId",
                "finalActionId",
                "isFinalAction",
                "branchId",
                "branches",
                "isValid",
                "visibleActions",
            ]
        );
    }

    updateActionTree(node: Node, nodes: Node[], edges: Edge[]): Action {
        const action = this.deserializeActionTree(decamelizeKeys(this.setActionFromNode(node.id, node.data)));
        const actionOutgoers = getOutgoers(node, nodes, edges)?.filter(
            (nd) => nd?.data?.nodeType !== "placeholder" && node.id === nd?.data?.parentId
        );
        if (actionOutgoers && actionOutgoers.length > 0) {
            const branch = nodes?.find((n: Node) => n?.id === node?.data?.branchId);
            action.actions = actionOutgoers
                .filter((nd: Node) => {
                    if (branch?.data?.finalActionId) {
                        return nd.id !== branch?.data?.finalActionId;
                    }

                    return true;
                })
                .map((nd: Node) => {
                    if (nd?.data?.nodeType === "condition") {
                        // Convert a condition node to its child property.
                        const branchOfCondition = nodes.find((n) => n.id === nd.data.branchId);

                        if (branchOfCondition) {
                            const condition = branchOfCondition?.data?.branches?.find((c: Node) => c.id === nd.id);
                            const childNode = getOutgoers(nd, nodes, edges)?.[0];

                            if (childNode) {
                                const newNode: Node = {
                                    ...childNode,
                                    data: {
                                        ...childNode.data,
                                        parentId: nd.data.branchId,
                                        ...(!condition?.data?.isDefault
                                            ? {
                                                  condition: {
                                                      uuid: nd.id,
                                                      conditionSets:
                                                          condition?.conditionSets?.map(
                                                              (conditionSet: ICondition[]) => {
                                                                  // Add uuid property to a condition.
                                                                  return conditionSet.map((cond: ICondition) => {
                                                                      return {
                                                                          ...cond,
                                                                          uuid: cond.id,
                                                                      };
                                                                  });
                                                              }
                                                          ) ?? [],
                                                  },
                                              }
                                            : { default: true }),
                                    },
                                };
                                return this.updateActionTree(newNode, nodes, edges);
                            }
                        }
                    }

                    return this.updateActionTree(nd, nodes, edges);
                });
        }

        if (node?.data?.nodeType === "branch") {
            const finalNode = nodes.find((nd) => nd.id === node?.data?.finalActionId);

            if (finalNode) {
                if (finalNode?.data?.nodeType !== "placeholder") {
                    action.action = this.updateActionTree(finalNode, nodes, edges);
                } else {
                    action.action = undefined;
                }
            }
        }

        return action;
    }

    convertReactflowToWorkflow(
        workflow: Workflow,
        nodes: Node[],
        edges: Edge[],
        settings: IWorkflowSettings
    ): IWorkflowResponse {
        const root = nodes?.[0];

        if (root) {
            this.id = workflow?.id;
            this.name = workflow?.name;
            this.projectId = workflow.projectId;
            this.uuid = workflow.uuid;
            this.trigger = plainToClass(
                Trigger,
                decamelizeKeys({
                    ...this.setActionFromNode(root.id, root.data),
                    id: root.id,
                    uuid: root.id,
                })
            );
            this.communicateOnlyDuringBusinessHours = settings.communicateOnlyDuringBusinessHours;
            this.excludedWorkflowIds = settings.excludedWorkflowIds;

            const rootOutgoers = getOutgoers(root, nodes, edges);

            if (rootOutgoers.length > 0 && rootOutgoers?.[0]) {
                const firstNode = rootOutgoers?.[0];
                if (firstNode) {
                    this.trigger.action = this.updateActionTree(firstNode, nodes, edges);
                }
            }
        }

        return this;
    }

    updateName(name: string): Workflow {
        this.name = name;
        return this;
    }
}

const initWorkflow = (workflowData: IWorkflowResponse): Workflow => {
    return new Workflow(workflowData);
};

export default initWorkflow;
