/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";

import { Col, Form, Input, Row, Select } from "antd";
import { isEmpty } from "lodash-es";

import { IFormsProps } from "../../../types";
import FormFooter from "../FormFooter";

interface IFormInputs {
    staff_ids: number[];
    ticket_type: string;
    subject: string;
    description: string;
}

export const PAYMENT_TYPES = [
    { value: "card", label: "Card" },
    { value: "cash", label: "Cash" },
];

export const CURRENCY = [{ value: "usd", label: "USD" }];

export default function CreatePaymentForm({
    initialData,
    canDelete = true,
    onCancel,
    onSubmit,
    onDelete,
}: IFormsProps) {
    const [form] = Form.useForm();

    const initialValues = {
        title: initialData.label,
        amount: initialData?.amount,
        currency: initialData?.currency,
        payment_type: initialData?.payment_type ?? initialData?.typePayment,
    };

    const sumbit = (data: IFormInputs) => {
        onSubmit(data);
    };

    React.useEffect(() => {
        if (initialData && !isEmpty(initialData)) {
            form.setFieldValue("amount", initialData?.amount);
            form.setFieldValue("payment_type", initialData?.payment_type ?? initialData?.typePayment ?? "card");
            form.setFieldValue("currency", initialData?.currency ?? "usd");
        }
    }, [initialData, form]);

    return (
        <div className="form-layout">
            <Form layout="vertical" form={form} initialValues={initialValues} onFinish={sumbit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Amount"
                            name="amount"
                            rules={[{ required: true, message: "Amount is required field" }]}
                        >
                            <Input placeholder="Amount" type="number" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Currency" name="currency">
                            <Select
                                className="field"
                                options={CURRENCY?.map((type) => ({
                                    label: type.label,
                                    value: type.value,
                                }))}
                                defaultValue={CURRENCY[0]}
                                placeholder="Select"
                                getPopupContainer={() =>
                                    document.getElementById("nodeSettingsPanelWrapper") as HTMLElement
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Type of payment" name="payment_type">
                            <Select
                                className="field"
                                options={PAYMENT_TYPES?.map((type) => ({
                                    label: type.label,
                                    value: type.value,
                                }))}
                                defaultValue={PAYMENT_TYPES[0]}
                                placeholder="Select"
                                getPopupContainer={() =>
                                    document.getElementById("nodeSettingsPanelWrapper") as HTMLElement
                                }
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <FormFooter onCancel={onCancel} canDelete={canDelete} onDelete={onDelete} />
            </Form>
        </div>
    );
}
